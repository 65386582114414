/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from "react";
import {
  Input,
  List,
  Typography,
  Card,
  Image,
  Button,
  Modal,
  Badge,
  Pagination,
} from "antd";
import "../page/pages.css";
import PageSlider from "../Slider/Slider";
import { useTranslation } from "react-i18next";
import Shopping from "./image/shopping-cart.png";
import info from "./image/about.png";
import { ArrowUpOutlined } from "@ant-design/icons";
import { animateScroll as scroll } from "react-scroll"; // Import the scroll function

const HomeShop = ({ handleClick }) => {
  const { t, i18n } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 9, // Number of items per page
    total: 0,
  });

  useEffect(() => {
    setLoading(true);
    const apiUrl = searchText
      ? `https://ecommerce-api.webappdevs.com/api/search?q=${searchText}`
      : "https://ecommerce-api.webappdevs.com/api/products";

    fetch(apiUrl)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((response) => {
        setLoading(false);
        setDataSource(response.data);
        setPagination({ ...pagination, total: response.data.length });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const onPageChange = (page) => {
    setPagination({ ...pagination, current: page });
  };
  const showItemDetails = (item) => {
    setSelectedItem(item);
    setModalVisible(true);
  };
  const renderData = () => {
    const { current, pageSize } = pagination;
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataSource.slice(startIndex, endIndex);
  };
  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };
  const [showScrollButton, setShowScrollButton] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div className="body-Home">
        <PageSlider handleClick={handleClick} />
        {/* <Space style={{ padding: "0px 16px" }} direction="vertical"> */}
        <Input.Search
          className="Search"
          placeholder={t("sent")}
          onSearch={(value) => {
            setSearchText(value);
          }}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <List
          loading={loading}
          grid={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
          dataSource={renderData()}
          // dataSource={dataSource}
          renderItem={(item) => {
            const nameTranslations = JSON.parse(item.name);
            // const descriptionTranslations = JSON.parse(item.description);

            const currentLanguage = i18n.language || "ku";
            const translatedName = nameTranslations[currentLanguage];
            // const translatedDescription =
            //   descriptionTranslations[currentLanguage];
            return (
              <Badge.Ribbon
                text={t("buy")}
                color="orange"
                style={{
                  margin: 9,
                }}
              >
                <Card
                  className="itemCard"
                  // honorable
                  honorable="true" // Pass string value instead of boolean
                  style={{
                    fontSize: 20,
                    direction: "rtl",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                  title={translatedName}
                  key={item.id}
                  // handleClick={handleClick}
                  cover={
                    item.image_url ? (
                      <Image
                        src={`https://ecommerce-api.webappdevs.com/storage/${item.image_url}`}
                        className="Image_Card"
                      />
                    ) : null
                  }
                  actions={[
                    <Button
                      type="link"
                      style={{
                        fontSize: "15px",
                      }}
                      onClick={() => handleClick(item)}
                    >
                      <div>
                        {t("Add_to_cart")}
                        <img src={Shopping} alt=" " width={25} height={25} />
                      </div>
                    </Button>,

                    <Button
                      type="link"
                      style={{
                        fontSize: "17px",
                      }}
                      onClick={() => showItemDetails(item)}
                    >
                      <div>
                        {t("info")}
                        <img src={info} alt=" " width={25} height={25} />
                      </div>
                    </Button>,
                  ]}
                >
                  <Card.Meta
                    title={
                      <Typography.Paragraph className="product">
                        {t("product")}: ${item.discounted_price}
                        <Typography.Text
                          delete
                          type="danger"
                          className="discount"
                        >
                          <span> </span>
                          {parseFloat(item.price).toFixed(0)}
                        </Typography.Text>
                        <Typography.Paragraph className="point">
                          {t("point")}: {item.points}
                        </Typography.Paragraph>
                      </Typography.Paragraph>
                    }
                  ></Card.Meta>
                </Card>
              </Badge.Ribbon>
            );
          }}
        ></List>

        <Pagination
          style={{ textAlign: "center" }}
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          onChange={onPageChange}
        />
        {showScrollButton && (
          <Button
            type="primary"
            className="scroll-to-top-button"
            onClick={scrollToTop} // Add onClick handler for scrolling to top
          >
            <ArrowUpOutlined />
          </Button>
        )}
        <Modal
          style={{ direction: "rtl" }}
          title={
            selectedItem?.name &&
            JSON.parse(selectedItem?.name)[i18n.language || "ku"]
          }
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={[
            <Button key="close" onClick={() => setModalVisible(false)}>
              {t("Close")}
            </Button>,
          ]}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>
              {selectedItem?.description &&
                JSON.parse(selectedItem?.description)[i18n.language || "ku"]}
            </p>
            {selectedItem?.image_url && (
              <img
                style={{ width: "40%" }}
                src={`https://ecommerce-api.webappdevs.com/storage/${selectedItem?.image_url}`}
              />
            )}
            {/* <img
                  style={{ width: "40%" }}
                  src={`https://bardawamplus.com/api/storage/${selectedItem?.image_url}`}
                />
              )} */}
          </div>
        </Modal>
        {/* </Space> */}
      </div>
    </div>
  );
};

export default HomeShop;
